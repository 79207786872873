<template>
  <b-container
    v-if="loaded"
    fluid
  >
    <b-row class="d-flex justify-content-center p-3">
      <b-col md="6">
        <h1>{{ $route.params.id ? 'Edit Template' : 'New Template' }}</h1>
        <hr>
        <b-form @submit="onSubmit">
          <b-form-group
            label="Name"
            label-size="lg"
            label-class="font-weight-bold pt-0"
          >
            <b-row>
              <b-col
                v-for="language in languages"
                :key="'name_' + language.id"
                md="6"
              >
                <b-form-input
                  v-model="template.name[language.code]"
                  required
                ></b-form-input>
                {{ language.name }}
              </b-col>
            </b-row>
          </b-form-group>
          <b-row>
            <b-col md="6">
              <b-form-group
                label="Location"
                label-size="lg"
                label-class="font-weight-bold pt-0"
              >
                <b-form-select
                  v-model.number="template.location_id"
                  class="mb-3"
                  required
                  text-field="correctedName"
                  value-field="id"
                  :options="englishName(locations)"
                />
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group
                label="Category"
                label-size="lg"
                label-class="font-weight-bold pt-0"
              >
                <b-form-select
                  v-model="selectedCategories"
                  class="mb-3"
                  required
                  text-field="correctedName"
                  value-field="id"
                  :options="englishName(categories)"
                />
              </b-form-group>
            </b-col>
          </b-row>
          <b-form-group
            label="Image"
            label-size="lg"
            label-class="font-weight-bold pt-0"
          >
            <div v-if="!images.length">
              <FileUpload
                :value="images"
                @input="uploadedImage"
              />
            </div>
            <div
              v-else
              style="width:150px;position:relative;"
            >
              <b-img
                :src="`${$config.cdnUrl}${images[0].src}`"
                thumbnail
              />
              <div
                class="remove-icon-wrapper"
              >
                <span
                  class="fa-stack"
                  title="Remove"
                  @click="images = []"
                >
                  <i class="fas fa-circle fa-stack-2x text-danger" />
                  <i class="fas fa-times fa-stack-1x fa-inverse" />
                </span>
              </div>
            </div>
          </b-form-group>
          <b-form-group
            label="Description"
            label-size="lg"
            label-class="font-weight-bold pt-0"
          >
            <b-row>
              <b-col
                v-for="language in languages"
                :key="'description_' + language.id"
                md="6"
              >
                <b-form-textarea
                  v-model="template.description[language.code]"
                  class="form-control"
                  rows="3"
                />
                {{ language.name }}
              </b-col>
            </b-row>
          </b-form-group>
          <b-form-group
            label="What to Bring"
            label-size="lg"
            label-class="font-weight-bold pt-0"
          >
            <b-row>
              <b-col
                v-for="language in languages"
                :key="'to_bring_' + language.id"
                md="6"
              >
                <b-form-textarea
                  v-model="template.to_bring[language.code]"
                  class="form-control"
                  rows="3"
                />
                {{ language.name }}
              </b-col>
            </b-row>
          </b-form-group>
          <b-row>
            <b-col md="6">
              <b-form-group
                label="Contact Name"
                label-size="lg"
                label-class="font-weight-bold pt-0"
              >
                <b-form-input
                  v-model="template.contact_name"
                  required
                ></b-form-input>
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group
                label="Contact WeChat ID"
                label-size="lg"
                label-class="font-weight-bold pt-0"
              >
                <b-form-input
                  v-model="template.contact_wechat"
                  required
                ></b-form-input>
              </b-form-group>
            </b-col>
          </b-row>
          <b-form-group
            label="Duration"
            label-size="lg"
            label-class="font-weight-bold pt-0"
          >
            <b-row>
              <b-col md="6">
                <datetime
                  type="time"
                  input-class="form-control"
                  :value="template.start_time"
                  @input="formatDateToTime($event, 'start_time')"
                ></datetime>
                Start Time
              </b-col>
              <b-col md="6">
                <datetime
                  type="time"
                  input-class="form-control"
                  :min-datetime="template.start_time"
                  :value="template.end_time"
                  @input="formatDateToTime($event, 'end_time')"
                ></datetime>
                End Time
              </b-col>
            </b-row>
          </b-form-group>
          <b-form-group
            label="Registration Start"
            label-size="lg"
            label-class="font-weight-bold pt-0"
          >
            <b-row>
              <b-col md="6">
                <b-form-input
                  type="number"
                  min="1"
                  step="1"
                  :disabled="!template.start_time"
                  :value="registrationOffsetHours"
                  @input="setRegistrationOffset"
                ></b-form-input>
                Hours before workout begins
              </b-col>
              <b-col v-if="template.start_time" md="6">
                {{ readableOffset }}
              </b-col>
            </b-row>
          </b-form-group>
          <b-form-group
            label="Recurrence Period (Same date if one-off)"
            label-size="lg"
            label-class="font-weight-bold pt-0"
          >
            <b-row>
              <b-col md="6">
                <datetime
                  type="date"
                  input-class="form-control"
                  v-model="template.dtstart"
                ></datetime>
                Start Time
              </b-col>
              <b-col md="6">
                <datetime
                  type="date"
                  input-class="form-control"
                  v-model="template.until"
                  :min-datetime="template.dtstart"
                ></datetime>
                End Time
              </b-col>
            </b-row>
          </b-form-group>
          <b-form-group
            label="Select Workout Day(s) of Week"
            label-size="lg"
            label-class="font-weight-bold pt-0"
          >
            <b-form-checkbox-group
              id="days"
              name="daysofweek"
              :options="dayOptions"
              :checked="bitToDays"
              @input="setSelectedDays"
            />
          </b-form-group>
          <b-form-group
            label="Recurring Frequency Details"
            label-size="lg"
            label-class="font-weight-bold pt-0"
          >
            <b-row>
              <b-col
                v-for="language in languages"
                :key="'frequency_details_' + language.id"
                md="6"
              >
                <b-form-input
                  v-model="template.frequency_details[language.code]"
                  :placeholder="language.code === 'en' ? 'Example: Every Monday and Tuesday' : ''"
                >
                </b-form-input>
                {{ language.name }}
              </b-col>
            </b-row>
          </b-form-group>
          <b-form-group>
            <b-form-checkbox
              v-model="hasMaxLimit"
              @change="hasMaxLimit && (template.max_attendees = null)"
            >Workouts have capped attendance</b-form-checkbox>
          </b-form-group>
          <b-form-group
            v-if="hasMaxLimit"
            label="Max Attendees"
          >
            <b-row>
              <b-col md="6">
                <b-form-input
                  v-model.number="template.max_attendees"
                  required
                  type="number"
                  :max="template.participants || ''"
                />
              </b-col>
            </b-row>
          </b-form-group>
          <b-form-group>
            <b-form-checkbox
              v-model.number="template.is_aqi_dependent"
              :value="1"
              :unchecked-value="0"
            >
            Workouts are AQI dependent
            </b-form-checkbox>
          </b-form-group>
          <b-form-group>
            <b-form-checkbox
              v-model.number="template.members_only"
              :value="1"
              :unchecked-value="0"
            >
            Members Only
            </b-form-checkbox>
          </b-form-group>
          <b-button
            class="mr-3"
            type="submit"
            variant="primary"
          >Submit</b-button>
        </b-form>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import moment from 'moment'
import { Datetime } from 'vue-datetime'
import { englishName } from '@/common'
import { makeGetRequest } from '@/util'
import FileUpload from '@/components/FileUpload'

export default {
  name: 'CreateEditWorkout',
  components: {
    FileUpload,
    datetime: Datetime
  },
  data () {
    return {
      template: {
        location_id: null,
        name: {},
        description: {},
        to_bring: {},
        start_time: '00:00:00',
        end_time: '01:00:00',
        registration_start_offset: '-04:00:00',
        registration_end_offset: '01:30:00',
        max_attendees: null,
        frequency_details: {},
        contact_name: null,
        contact_wechat: null,
        is_aqi_dependent: null,
        dtstart: moment().toISOString(),
        until: moment().add(5, 'years').toISOString(),
        interval: 1,
        byday: null,
        weekdays: null,
        members_only: 0,
        status: 'active'
      },
      categories: [],
      languages: [],
      locations: [],
      selectedCategories: [],
      selectedDays: [],
      dayOptions: [
        { text: 'Monday', value: 1, short: 'mo' },
        { text: 'Tuesday', value: 2, short: 'tu' },
        { text: 'Wednesday', value: 3, short: 'we' },
        { text: 'Thursday', value: 4, short: 'th' },
        { text: 'Friday', value: 5, short: 'fr' },
        { text: 'Saturday', value: 6, short: 'sa' },
        { text: 'Sunday', value: 0, short: 'su' }
      ],
      images: [],
      hasMaxLimit: false,
      errors: '',
      loaded: false
    }
  },
  computed: {
    bitToDays () {
      const { template: { weekdays }, dayOptions } = this
      if (weekdays <= 0) return []
      return dayOptions
        .filter((o) => (weekdays & this.dayToBit(o.value)) > 0) // eslint-disable-line
        .map((o) => o.value)
    },
    registrationOffsetHours () {
      const { template } = this
      if (!template.registration_start_offset) return 0
      return this.toHours(template.registration_start_offset)
    },
    readableOffset () {
      const { template } = this
      const value = this.registrationOffsetHours
      const calcTime = moment.duration(template.start_time).subtract(value, 'hours').asHours()
      const duration = moment.duration(template.start_time).subtract(value, 'hours')
      const hours = duration.hours()
      const days = Math.abs(Math.floor(duration.asHours() / 24) % 24)
      const minutesPlaceholder = duration.minutes()
      let outString
      if (calcTime >= 0) {
        // x time on day of workout
        outString = `${this.formatTime(hours)}:${this.formatTime(minutesPlaceholder)} on day of workout`
      } else {
        // x time x days before workout
        const workoutHours = this.formatTime((Math.floor(duration.asHours() % 24) + 24) % 24)
        outString = `
          ${workoutHours}:${this.formatTime((minutesPlaceholder + 60) % 60)}, ${days} days before workout
        `
      }
      return outString
    }
  },
  methods: {
    englishName,
    setSelectedDays (value) {
      if (!value.length) {
        this.template.weekdays = ''
        this.template.byday = ''
      } else {
        this.template.weekdays = value.reduce((acc, cur) => (
          acc += this.dayToBit(cur)
        ), 0)
        this.template.byday = value.map((v) => (
          this.dayOptions.find((option) => option.value === v).short
        )).join(',')
      }
    },
    dayToBit (dayVal) {
      return 2 ** dayVal
    },
    // bitToDays (bitVal) {
    //   if (!bitVal) return []
    //   return this.dayOptions
    //     .filter((o) => this.dayToBit(o.value) > 0)
    //     .map((o) => o.value)
    // },
    formatDateToTime (value, key) {
      return this.template[key] = moment(value).format('HH:mm:ss')
    },
    setRegistrationOffset (value) {
      const offset = `-${this.formatTime(value)}:00:00`
      this.template.registration_start_offset = offset
    },
    toHours (value) {
      if (!value) return 0
      const timeIndex = value.indexOf(':')
      return Math.abs(
        Number(
          value
            .substring(timeIndex - 2, timeIndex)
        )
      )
    },
    formatTime (value) {
      return value.toString().length > 1 ? value : `0${value}`
    },
    uploadedImage (value) {
      this.images.push(value)
    },
    async onSubmit (e) {
      e.preventDefault()
      const { template, selectedCategories: categories, images: imageArr } = this
      const images = imageArr.map((s) => s.id)
      template.categories = [categories]
      template.images = images
      try {
        let url = `${this.$config.apiUrl}/templates`
        let method = 'post'
        if (this.$route.params.id) {
          url += `/${this.$route.params.id}`
          method = 'put'
        }
        await this.axios({
          method,
          url,
          data: template
        })
        this.$router.replace('/admin/templates')
      } catch (error) {
        // console.log(error)
      }
    },
    async prepareData () {
      const [categories, languages, locations] = await Promise.all([
        makeGetRequest(`${this.$config.apiUrl}/categories`),
        makeGetRequest(`${this.$config.apiUrl}/languages`),
        makeGetRequest(`${this.$config.apiUrl}/locations`)
      ])
      this.categories = categories
      this.languages = languages
      this.locations = locations
    }
  },
  async created () {
    try {
      await this.prepareData()
      if (this.$route.params.id) {
        const url = `${this.$config.apiUrl}/templates/${this.$route.params.id}?include=images,categories`
        const {
          images,
          categories,
          ...template
        } = await makeGetRequest(url)
        const jsonFields = ['name', 'description', 'to_bring', 'frequency_details']
        Object.keys(template).forEach((k) => {
          if (jsonFields.indexOf(k) !== -1 && !template[k]) template[k] = {}
        })
        this.template = template
        this.images = images
        this.selectedCategories = categories[0].id
        if (template.max_attendees > 0) this.hasMaxLimit = true
      }
    } catch (error) {
      // console.error(error)
    } finally {
      this.loaded = true
    }
  }
}
</script>

<style>
</style>
